import React, { useEffect, useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import { UserService } from "../../../app/service/user.service";
import moment from "moment";
import ReactDOMServer from "react-dom/server";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import signImg from "../../../assets/images/img/sign.png";
import qcImg from "../../../assets/images/img/qr-code.png";
import compImg from "../../../assets/images/img/Logo.png";

const Usercertificates = () => {
  const [tableData, setTableData] = useState([]);

  const dateFormat = (dateStr: any) => {
    const date = moment(dateStr);
    const formattedDate = date.format("DD/MMM/YYYY");
    return formattedDate;
  };

  useEffect(() => {
    getCertificates();
  }, []);

  const getCertificates = () => {
    const userdata: any = sessionStorage.getItem("loginData");
    const userRole = JSON.parse(userdata);
    const email = userRole.email;
    const data = {
      email: email,
    };

    // showLoader();

    UserService.certificatesList(data)
      .then((res: any) => {
        if (res.status === "Success") {
          setTableData(res.data);
        } else {
          // toast.error(res.message);
        }
        // hideLoader();
      })
      .catch((error) => {
        toast.error("An error occurred while fetching data.");
        // hideLoader();
      });
  };
  const handleView = async (item: any, type: any) => {
    let certificateHtml;

    switch (type) {
      case "InternshipCertificate":
        certificateHtml = ReactDOMServer.renderToString(IntenshipT(item));
        break;
      case "TrainingCertificate":
        certificateHtml = ReactDOMServer.renderToString(IntenshipC(item));
        break;
      case "ParticipationCertificate":
        certificateHtml = ReactDOMServer.renderToString(IntenshipP(item));
        break;
      default:
        certificateHtml = "";
    }

    const container1 = document.createElement("div");
    container1.style.position = "fixed";
    // container1.style.top = "-10000px"; 
    // container1.style.left = "-8000px"; 
    // container1.style.width = "100%";
    // container1.style.minHeight = "100%";
    container1.style.padding = "0px"; 
    // container1.style.margin = "auto";
    container1.innerHTML = certificateHtml;
    document.body.appendChild(container1);

    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Render the HTML content to canvas
    const canvas = await html2canvas(container1, {
      scale: 2, // Higher scale for better resolution
      useCORS: true, // Enable cross-origin images loading
      scrollX: 0,
      scrollY: 0,
      width: container1.scrollWidth,
      height: container1.scrollHeight,
    });

    // Clean up the temporary HTML container immediately
    document.body.removeChild(container1);

    // Convert canvas to image data
    const imgData = canvas.toDataURL("image/png");

    // Initialize jsPDF with custom dimensions
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [canvas.width, canvas.height], // Set dimensions to match canvas
    });

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    // Add the image to the PDF
    pdf.addImage(imgData, "PNG", 0, 0, pageWidth, pageHeight);

    // Save the PDF
    pdf.save("certificate.pdf");
  };

  const IntenshipC = (item: any) => {
    const date = new Date(item.createdAt).toLocaleDateString();
    const startDate = dateFormat(item.startDate);
    const endDate = dateFormat(item.endDate);
    let genderValue = item.gender === "her" ? "he" : "she";
    return (
      <div className="main-section-c">
        <div className="container pb-5">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 text-center">
              <h1 className="head-1-1 pt-4 mb-0">CERTIFICATE</h1>
              <span className="head-3-1">OF TRAINING</span>
              <p className="para-1 mt-3">
                THIS CERTIFICATE IS <br /> PROUDLY PRESENTED TO
              </p>
              <h1 className="head-2-1 pb-2 text-dark">{item.userName}</h1>
              <span className="op-8 pt-5 ">
                This is to certify that the above-mentioned candidate, has
                successfully completed the
                <b> {item.courseName}</b> training program conducted from{" "}
                <b>
                  {startDate} to {endDate}
                </b>
                .
              </span>
              <p className="op-8 pt-2 ">
                Throughout the training period,{genderValue} demonstrated a
                strong commitment to learning and exhibited a commendable
                understanding of the program.
              </p>
              <div className="d-flex justify-content-between pt-2 pe-5">
                <div>
                  <div>
                    <img src={qcImg} width="150px" alt="" />
                  </div>
                  <div className="sign">
                    <span className="para-1 mt-2 text-uppercase">Director</span>
                  </div>
                </div>
                <div className="mt-4 pt-2">
                  <div className="">{date}</div>

                  <div className="sign">
                    <span className="para-1 text-uppercase">Date</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
    );
  };
  const IntenshipT = (item: any) => {
    const date = new Date(item.createdAt).toLocaleDateString();
    const startDate = dateFormat(item.startDate);
    const endDate = dateFormat(item.endDate);
    const gender = item?.gender?.toLowerCase(); 
     const capitalizedGender = gender.charAt(0).toUpperCase() + gender.slice(1);
    return (
      <>
        <div className="main-section">
          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-8 ps-5">
                <h1 className="head-1 pt-5 mb-0">CERTIFICATE</h1>
                <span className="op-7 head-3">of Internship</span>
                <h5 className="op-7 para">Proudly awarded to</h5>
                <h1 className="head-2 pt-2 pb-2">{item.userName}</h1>

                <span className="pt-5">
                {capitalizedGender} successfully completed program in
                  <b> {item.courseName}</b> from{" "}
                  <b>
                    {startDate} to {endDate}
                  </b>
                  . We found {item.gender} sincere, hardworking, dedicated and
                  result oriented. We take this opportunity to wish{" "}
                  {item.gender} all the best for future.
                </span>
                <div className="d-flex justify-content-between mt-5 pt-5">
                  <div>
                    <div>
                      <img src={signImg} width="150px" alt="" />
                    </div>
                    <div className="sign">
                      <span className="op-7 mt-2">Director</span>
                    </div>
                  </div>
                  <div>
                    <div className="">
                      <div className="text-center">
                        <img src={qcImg} width="80px" alt="QR-code" />
                      </div>
                      <div className="text-center">
                        <h6 className="op-7 mb-0">Sacn to validate</h6>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 pt-2">
                    <div className="">{date}</div>

                    <div className="sign">
                      <span className="op-7">Date</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const IntenshipP = (item: any) => {
    const date = new Date(item.createdAt).toLocaleDateString();
    const startDate = dateFormat(item.startDate);
    const endDate = dateFormat(item.endDate);
    return (
      <>
        <div className="main-section-p">
          <div className="container pt-5 pb-5">
            <div className="row p-0">
              <div className="col-md-8 ps-5 text-center">
                <div className="mb-3">
                  <img src={compImg} width="250px" alt="" />
                </div>
                <span className="head-4">CERTIFICATE OF PARTICIPATION</span>
                <p className="para-1 mt-3">
                  THIS CERTIFICATE IS PROUDLY PRESENTED TO:
                </p>
                <div className="d-flex justify-content-center mb-2">
                  <h1
                    className="head-2 pt-2 pb-2 sign-2"
                    style={{ color: "#b4953f" }}
                  >
                    {item.userName}
                  </h1>
                </div>

                <span className="op-8 ">
                  For {item.gender} active and invaluable participation in the
                  <b> {item.courseName}</b> from{" "}
                  <b>
                    {startDate} to {endDate}
                  </b>{" "}
                  and {item.gender} successfully completed the program.
                </span>
                <div className="d-flex justify-content-between mt-5 pt-5 ps-5 pe-5">
                  <div>
                    <div>
                      <img src={signImg} width="150px" alt="" />
                    </div>
                    <div className="sign-1">
                      <span className="para-1 mt-2 text-uppercase">
                        Director
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="">
                      <div className="text-center">
                        <img src={qcImg} width="80px" alt="QR-code" />
                      </div>
                      <div className="text-center">
                        <h6 className="op-7 mb-0">Sacn to validate</h6>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 pt-2">
                    <div className="">{date}</div>

                    <div className="sign-1">
                      <span className="para-1 text-uppercase">Date</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg mt-5 px-5">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ color: "white" }} className="primary-bg">
                    Domain Name
                  </th>
                  <th style={{ color: "white" }} className="primary-bg">
                    Internship Period
                  </th>
                  <th style={{ color: "white" }} className="primary-bg">
                    Issue Dates
                  </th>
                  <th style={{ color: "white" }} className="primary-bg">
                    Certificate Types
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{item.courseName}</td>
                      <td>
                        {dateFormat(item.startDate)} to{" "}
                        {dateFormat(item.endDate)}
                      </td>
                      <td>{dateFormat(item.createdAt)}</td>
                      <td>
                        {item.certificateType.map((type: string, i: number) => (
                          <>
                            <button
                              key={i}
                              className="btn btn-success mt-2"
                              onClick={() => handleView(item, type)}
                            >
                              {type}
                            </button>
                            <br />
                          </>
                        ))}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usercertificates;
