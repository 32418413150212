import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components/tables";
import { SignUpListDataGridCols } from "./SignUpListDataGridCols";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";

const SignUpList = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const[signUpData,setSignUpData]=useState([]);

  const handleCancel = () => {
    setName("");
  };
  const handleSearch = () => {};
  const handleSignUpList = () => {
    navigate("/support/addSignUpList");
  };
  useEffect(() => {
    getSignUpData();
  }, []);

  const getSignUpData = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.allSignUpList(data).then((res: any) => {
      if (res.status === "Success") {
        setSignUpData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };
  const deleteSignUpListInfo = (SignupListId: string) => {
    const payload = {
      SignupListId: SignupListId,
    };
    AdminService.deleteSignUpList(payload).then((res: any) => {
      if (res.status === "Success") {
        toast.success(res.message);
        getSignUpData();
      } else {
        toast.error(res.message);
      }
    });
  };
  return (
    <div>
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <div className="row">
            <div className="col-md-6 mt-3">
              <label className="form-label d-flex justify-content-start text-start">
                <b>SignUp List</b>
              </label>
              <div className="d-flex">
                <input
                  name="User Name"
                  onChange={(e: any) => setName(e.target.value)}
                  className="form-control mt-1 me-2"
                  value={name}
                  placeholder="Enter the Title"
                />
                <button
                  className="btn btn-primary rounded px-4 py-1"
                  onClick={() => handleSearch()}
                >
                  Search
                </button>
                <button
                  className="btn btn-secondary rounded ms-2 px-4 py-1"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="text-end">
                <button
                  className="btn btn-success"
                  onClick={handleSignUpList}
                >
                  Create Add SignUp List
                </button>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <DataTable tableData={signUpData} TableCols={SignUpListDataGridCols} deleteSignUpListInfo={deleteSignUpListInfo}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default SignUpList;

