import React, { useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";

const AddSignUpList = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({
    userName: "",
    userCount: "",
  });
  const [errors, setErrors] = useState<any>({});

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors: any = {};

    if (!formData.userName) {
      newErrors.userName = "User Name is required";
    }

    if (!formData.userCount) {
      newErrors.userCount = "User Count is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!validate()) return;

    let payload = {
      userName: formData.userName,
      userCount: formData.userCount,
    };

    AdminService.createSignupList(payload)
      .then((res) => {
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          toast.success(res.message);
          navigate("/support/signUpList");
        }
      })
      .catch((err) => {
        toast.error("An error occurred while creating LMS Video");
      });
  };

  const handleCancel = () => {
    navigate("/support/signUpList");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Add SignUp List</b>
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Add User Name</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.userName ? "is-invalid" : ""
                    }`}
                    placeholder="Add User Name"
                    name="userName"
                    onChange={handleInput}
                  />
                  {errors.userName && (
                    <div className="invalid-feedback">{errors.userName}</div>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">User Count</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.userCount ? "is-invalid" : ""
                    }`}
                    placeholder="Add User Count"
                    name="userCount"
                    onChange={handleInput}
                  />
                  {errors.userCount && (
                    <div className="invalid-feedback">{errors.userCount}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <button type="submit" className="btn btn-primary me-2">
                Create
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddSignUpList;
