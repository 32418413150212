import React, { useEffect, useState } from "react";
import HomeVideo from "./../../assets/images/home_video.png";
import { useNavigate } from "react-router-dom";
import TakeOurSectionRightImage from "./../../assets/images/take-our-section-right-image.jpg";
import { AdminService } from "../../app/service/admin.service";
import { toast } from "react-toastify";

export const AcquireSkills = () => {
  const videoUrl = "https://www.youtube.com/watch?v=7WrHNF9KKso";
  const [data, setData] = useState<any>([]);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/programs");
  };
  useEffect(() => {
    getSignUp();
  }, []);

  const getSignUp = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getAllSignUpList(data).then((res: any) => {
      if (res.status === "Success") {
        setData(res?.data[0]);
      } else {
        toast.error(res.message);
      }
    });
  };
  console.log("data", data?.userCount);
  return (
    <div className="col-md-12">
      <div className="hero-section">
        <span className="primary-color">Acquire skills</span> today that will
        remain
        <br /> relevant and valuable in the{" "}
        <span className="primary-color">future.</span>{" "}
      </div>
      <div className="secondary-color">
        <div className="me-3 d-flex justify-content-center">
          <img
            src={TakeOurSectionRightImage}
            className="take-our-section-right-image"
            alt="Student 1"
          />
          <div className="student-text mt-3">
            Join <span className="text-white fw-bold">{data?.userCount}+</span>{" "}
            students who have <br />{" "}
            <span className="" style={{float:"left"}}>already signed up</span>
          </div>
        </div>
      </div>

      <div className="mt-2 mb-4">
        <button
          className="bg-white px-3 py-2 secondary-btn"
          onClick={handleClick}
        >
          Explore Programs
        </button>
      </div>
      {/* <video controls autoPlay className='h-50 mb-5 acquire-skills-video'>
                <source src={videoUrl} type="video/mp4" />
            </video> */}
      <div className="">
        <img src={HomeVideo} className="h-50 mb-5 acquire-skills-video" />
      </div>
      {/* <div className='row d-md-flex justify-content-between mx-2 mx-md-5 mb-5'>
                <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-3 px-md-5 py-1 py-md-3'>
                    <div>Rated 5/5</div>
                    <div className='py-1'><span className='me-2 '><img src={Google} alt="" /></span><span>Google</span></div>
                </div>
                <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-3 px-md-5 py-1 py-md-3'>
                    <div>Rated 5/5</div>
                    <div className='py-1'><span className='me-2 '><img src={Facebook} alt="" /></span><span>Facebook</span></div>
                </div>
                <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-3 px-md-5 py-1 py-md-3'>
                    <div>Rated 5/5</div>
                    <div className='py-1'><span className='me-2 '><img src={Glassdoor} alt="" /></span><span>Glassdoor</span></div>
                </div>
                <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-3 px-md-5 py-1 py-md-3'>
                    <div>Rated 5/5</div>
                    <div className='py-1'><span className='me-2 '><img src={Indeed} alt="" /></span><span>Indeed</span></div>
                </div>
                <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-3 px-md-5 py-1 py-md-3'>
                    <div>Rated 5/5</div>
                    <div className='py-1'><span className='me-2 '><img src={Kununu} alt="" /></span><span>Kununu</span></div>
                </div>
            </div>  */}
      {/* <div className='px-2 mx-md-5'>
                <div className='text-center mb-5'>
                    <div className='fs-2 fw-bold'>Our <span className='primary-color'>Alumini Partners</span> works at</div>
                </div>
                <div className='row d-md-flex justify-content-between mx-2 mx-md-5 mb-5'>
                    <div className='col-5 col-md-2'>
                        <img src={tcs} alt="" />
                    </div>
                    <div className='col-5 col-md-2'>
                        <img src={hcl} alt="" />
                    </div>
                    <div className='col-5 col-md-2'>
                        <img src={microsoft} alt="" />
                    </div>
                    <div className='col-5 col-md-2'>
                        <img src={infoys} alt="" />
                    </div>
                    <div className='col-5 col-md-2'>
                        <img src={techmahendra} alt="" />
                    </div>

                   
                </div>
            </div> */}
    </div>
  );
};
