import React, { useEffect, useState } from "react";
import { DataTableCol } from "./types";
// import { Pagination } from '../pagination';
// import Pagination from "react-js-pagination";
import ReactPaginate from "react-paginate";
import moment from "moment";

interface Props {
  tableData: any;
  TableCols: DataTableCol[];
  handleDeleteUser?: any;
  handleEditUser?: any;
  deleteRegisterInfo?: any;
  deleteAmbassadorInfo?: any;
  deleteMeetInfo?: any;
  handleEditMeet?: any;
  deleteQueriesInfo?: any;
  deleteCourseInfo?: any;
  handleEditCourse?: any;
  handleAccessData?: any;
  deletePaymentInfo?: any;
  generateCertificate?:any;
  handleCgpaAccess?:any;
  setFormData?:any;
  deleteSignUpListInfo?:any;
  deleteCareerGuidance?:any;
  deleteCgpa?:any

  // searchText?: any;
  // pageNumbers?: any;
  // pageNumber: (value: any) => void;
  // activePageNumber?: number;
  // editInfo?: any;
  // deleteInfo?: any;
  // onChange?: (value: any) => void;
  // onQuestionUpload?: (value: any) => void;
}

export const DataTable: React.FC<Props> = (props: Props) => {
  const [canShowDefaultArrow, setCanShowDefaultArrow] = useState(true);
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [tabledata, setTableData] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const items = props.tableData;
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  useEffect(() => {
    // const data = props.tableData;
  }, []);

  const onSortingField = (data: any) => {
    setCanShowDefaultArrow(false);
    const order =
      data === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(data);
    setSortingOrder(order);
    if (data) {
      const reversed = order === "asc" ? 1 : -1;
      const preparedData = props.tableData.sort(
        (a: any, b: any) => reversed * a[data].toString().localeCompare(b[data])
      );
      // console.log(preparedData);
    }
  };

  const handlePageClick = (e: any) => {
    const newOffset = (e.selected * itemsPerPage) % items.length;

    setItemOffset(newOffset);
  };
  const DeleteUser = (data: any) => {
    if (props.handleDeleteUser) {
      props?.handleDeleteUser(data._id);
    }
    if (props.deleteRegisterInfo) {
      props?.deleteRegisterInfo(data._id);
    }
    if (props?.deleteAmbassadorInfo) {
      props?.deleteAmbassadorInfo(data._id);
    }
    if (props?.deleteMeetInfo) {
      props?.deleteMeetInfo(data._id);
    }
    if (props?.deleteQueriesInfo) {
      props?.deleteQueriesInfo(data._id);
    }
    if (props?.deleteCourseInfo) {
      props?.deleteCourseInfo(data._id);
    }
    if (props?.deletePaymentInfo) {
      props?.deletePaymentInfo(data._id);
    }
    if(props?.deleteSignUpListInfo){
      props?.deleteSignUpListInfo(data._id);
    }
    if(props?.deleteCareerGuidance){
      props?.deleteCareerGuidance(data._id)
    }
    if(props?.deleteCgpa){
      props?.deleteCgpa(data)
    }
  };

  const EditUser = (data: any) => {
    if (props.handleEditUser) {
      props.handleEditUser(data);
    }
    if (props.handleEditMeet) {
      props.handleEditMeet(data);
    }
    if (props?.handleEditCourse) {
      props.handleEditCourse(data);
    }
  };
  const handleAccess = (data: any) => {
    if (props?.handleAccessData) {
      props?.handleAccessData(data);
    }
  };
  const handleCertificateSelection = (type: string,data:any) => {
    if(props?.generateCertificate){
      props?.generateCertificate(type,data);
    }
  };
  const ClickCgpaAcess=(data:any)=>{
    if(props?.handleCgpaAccess){
      props?.handleCgpaAccess(data);
    }
  }
  const handleClickDate=(e:any)=>{
    const { name, value } = e.target;
    props?.setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table_new_ui">
                <thead className="text-center">
                  <tr>
                    {props.TableCols.map((el, index) => (
                      <React.Fragment key={index}>
                        {el.canShowColumn && (
                          <th
                            onClick={() =>
                              el.sortable ? onSortingField(el.control) : null
                            }
                          >
                            {
                              <span style={{ fontSize: "16px" }}>
                                {el.title}
                              </span>
                            }
                            
                            {canShowDefaultArrow &&
                              index === 0 &&
                              el.sortable && (
                                <i className="bi bi-arrow-down cursor-pointer p-2 "></i>
                              )}
                            {sortingField && sortingField === el.control && (
                              <i
                                className={`${
                                  sortingOrder === "asc"
                                    ? "bi bi-arrow-down cursor-pointer p-2"
                                    : "bi bi-arrow-up cursor-pointer p-2"
                                }`}
                              ></i>
                            )}
                          </th>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-center">
                  {currentItems.map((data: any, i: number) => (
                    <tr key={i}>
                      {props.TableCols.map((el: any, index: number) => (
                        <React.Fragment key={index}>
                          <td style={{ fontSize: "18px" }}>
                            {data[el.control]}
                            {
                              el.control==="startDate" &&
                              <input type="date" onChange={handleClickDate} name="startDate"/>
                            }
                             {
                              el.control==="endDate" &&
                              <input type="date" onChange={handleClickDate} name="endDate"/>
                            }
                             {
                              el.control==="gender" &&
                              <input type="text" onChange={handleClickDate} name="gender"/>
                            }
                         

                            {el.title === "Actions" && (
                              <div className="actions-view">
                                <span style={{ cursor: "pointer" }}>
                                  {el.isAdd && (
                                    <button
                                      className="btn btn-info text-white"
                                      onClick={() => handleAccess(data)}
                                    >
                                      Access
                                    </button>
                                  )}
                                  {el.isAccess && (
                                    <button
                                      className="btn btn-info text-white"
                                      onClick={() => ClickCgpaAcess(data)}
                                    >
                                      Access
                                    </button>
                                  )}
                                  {el.isCerificate && (
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        action
                                      </button>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          onClick={() =>
                                            handleCertificateSelection(
                                              "InternshipCertificate",data
                                            )
                                          }
                                        >
                                          Intenship Certificate
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          onClick={() =>
                                            handleCertificateSelection(
                                              "ParticipationCertificate",data
                                            )
                                          }
                                        >
                                          Participation Certificate
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          onClick={() =>
                                            handleCertificateSelection(
                                              "TrainingCertificate",data
                                            )
                                          }
                                        >
                                          Training Certificate
                                        </a>
                                      </div>
                                    </div>
                                  )}

                                  {el.isEdit && (
                                    <i
                                      className="bi bi-pencil-square  cursor-pointer edit-icon p-2"
                                      onClick={() => EditUser(data)}
                                    ></i>
                                  )}
                                  {el.isDelete && (
                                    <i
                                      className="bi bi-trash text-danger fw-bold icon-size cursor-pointer p-2"
                                      onClick={() => DeleteUser(data)}
                                    ></i>
                                  )}
                                  {el.isView && (
                                    <span>
                                      <i className="bi bi-eye-fill cursor-pointer p-2"></i>
                                    </span>
                                  )}
                                </span>
                              </div>
                            )}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {currentItems.length === 0 && (
              <div className="mt-3 text-center">No records found.</div>
            )}
            {currentItems.length > 0 && (
              //  <Pagination totaldatacount={props.tableData.length} />
              // <div className='pagination d-flex justify-content-end'>
              //     <Pagination
              //         activePage={1}
              //         itemsCountPerPage={10}
              //         totalItemsCount={450}
              //         pageRangeDisplayed={5}
              //         onChange={handlePageChange}
              //         itemClass='page-item'
              //     />
              // </div>
              <div className="d-flex justify-content-end mb-4">
                <ReactPaginate
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={4}
                  marginPagesDisplayed={0}
                  pageCount={pageCount}
                  previousLabel="<"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel=""
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
