import React, { useEffect, useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { useNavigate } from "react-router-dom";
import { domains } from "../../../courseConfig";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import CmsCourseData from "./cmsCourseData";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import { useAppContext } from "../../../context/AppContext";


const Cms = () => {
  const {showLoader,hideLoader}:any=useAppContext();
  const [startIndex, setStartIndex] = useState(0);
  const [viewAll, setViewAll] = useState(false);
  const [isOpenCourse, setIsOpenCourse] = useState(false);
  const [courseData,setCourseData]=useState([]);
  const [lmsData,setLmsData]=useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    scrollToTop();
    getCoursesList();
    getLmsLists();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNext = () => {
    if (startIndex + 3 < domains.length) {
      setStartIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex((prevIndex) => prevIndex - 1);
    }
  };
  const handleClick = (domain:any) => {
    setCourseData(domain)
    setIsOpenCourse(true);
  };
  let percentage = 10;
  const getCoursesList = () => {
    // const data = {
    //   search: "",
    //   start: 1,
    //   count: 10,
    // };
    // showLoader();
    // AdminService.getCourseList(data).then((res: any) => {
    //   if (res.status === "Success") {
    //     const userdata: any = sessionStorage.getItem("loginData");
    //     const userRole = JSON.parse(userdata);
    //     const userCourses = userRole.courseName;
    //     const filteredData = res.data.filter((meet: any) =>
    //       userCourses.includes(meet.courseName)
    //     );
    //     setCourseData(filteredData);
    //     hideLoader();
    //   } else {
    //     toast.error(res.message);
    //     hideLoader();
    //   }
    // });
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    showLoader();
    AdminService.getCourseList(data).then((res: any) => {
      if (res.status === "Success") {
        const userdata: any = sessionStorage.getItem("loginData");
        const userRole = JSON.parse(userdata);
        const userCourses = userRole.courseName.map((course: string) => course.trim().toLowerCase());
        
        const filteredData = res.data.filter((meet: any) =>
          userCourses.includes(meet.courseName.trim().toLowerCase())
        );
        
        setCourseData(filteredData);
        hideLoader();
      } else {
        toast.error(res.message);
        hideLoader();
      }
    });
  };
  const getLmsLists = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    showLoader();
    AdminService.getallLmslist(data).then((res: any) => {
      if (res.status === "Success") {
        const userdata: any = sessionStorage.getItem("loginData");
        const userRole = JSON.parse(userdata);
        const userCourses = userRole.courseName;
        const filteredData = res.data.filter((meet: any) =>
          userCourses.includes(meet.courseName)
        );
        setLmsData(filteredData);
        hideLoader();
      } else {
        toast.error(res.message);
        hideLoader();
      }
    });
  };
  console.log("Lms==>",lmsData)
  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-4 py-4">
            {isOpenCourse ? (
              <>
                <CmsCourseData courseData={courseData} lmsData={lmsData}/>
              </>
            ) : (
              <>
                <h4>Select Domain</h4>
                <div className="row mb-5">
                  {courseData
                    .slice(startIndex, startIndex + 3)
                    .map((domain:any, index:number) => (
                      <div
                      key={index}
                      className="col-md-6 col-lg-4 col-xl-4 mb-3 float-start pb-5"
                    >
                      <div
                        key={index}
                        className="card primary-bg text-white mx-md-2"
                      >
                        <img
                          src={domain.image}
                          className="card-img-top trending-domain-card-image"
                          alt="..."
                        />
                           <div className="card-content text-start">
                            <div className="tags">
                              {/* <div className="tag">Development</div>
                              <div className="tag my-0 my-lg-2">Intermediate</div> */}
                              {/* <div className="seat my-lg-2">
                                <i className="fa-regular fa-user"></i>{" "}
                                {domain?.soldSeats}/{domain?.totalSeats} Seats
                              </div> */}
                            </div>
                            <h3 className="card-title">{domain.courseName}</h3>
                            <p className="card-text">{domain.description}</p>
                            <div className="mt-3">
                              <button
                                className="btn-learn-more"
                                onClick={() => handleClick(domain)}
                              >
                                Learn More
                              </button>
                            </div>
                          </div>
                        </div>
                        </div>
                    ))}
                  <div className="text-center">
                    {courseData?.length !==4 ? null : (
                      <>
                        <span onClick={handlePrev}>
                          {" "}
                          <MdArrowBackIosNew
                            className="secondary-btn"
                            style={{
                              fontSize: "28px",
                              border: "2px solid",
                              borderRadius: "50%",
                              padding: "3px",
                              cursor: "pointer",
                            }}
                          />
                        </span>
                        <span onClick={handleNext} className="ms-4">
                          <MdArrowForwardIos
                            className="secondary-btn"
                            style={{
                              fontSize: "28px",
                              border: "2px solid",
                              borderRadius: "50%",
                              padding: "3px",
                              cursor: "pointer",
                            }}
                          />
                        </span>
                      </>
                    )}
                  </div>

                  <br />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cms;
