import React from "react";
import "./App.css";
import "./assets/css/custom.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { BrowserRouter } from "react-router-dom";
import { RootNavigation } from "./routes";
import { NavBar } from "./components/nav-bar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppProvider } from "./context/AppContext";
import Loader from "./commonData/Loader";

function App() {
  return (
    <>
      <Loader></Loader>
      <AppProvider>
        <div className="App Eduexpose " style={{ scrollbarWidth: "none" }}>
          <ToastContainer />
          <BrowserRouter>
            <RootNavigation />
          </BrowserRouter>
        </div>
      </AppProvider>
    </>
  );
}

export default App;
