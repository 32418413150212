import { DataTableCol } from "../../../components/tables/types";

export const SignUpListDataGridCols: DataTableCol[] = [
    {
        title: 'User Name',
        control: 'userName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'User Count',
        control: 'userCount',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: false,
        isDelete: true,
        canShowColumn: true
    },
];